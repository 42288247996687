import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import React from 'react'

import Home from 'components/NFT/Home'
import Popular from 'components/NFT/Popular'
import About from 'components/NFT/About'
import Vision from 'components/NFT/Vision'
import Roadmap from 'components/NFT/Roadmap'
import FAQ from 'components/NFT/FAQ'

import effect1Img from '../../assets/images/effect-1.png'
import effect2Img from '../../assets/images/effect-2.png'


const Effect1 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  & img {
    width: 400px;
  }

`
const Effect2 = styled.div`
  position: absolute;
  top: 140px;
  right: 0px;
  display: none;
`

export default function Nft({ history }: RouteComponentProps) {

  return (
    <>

      <Effect1>
        <img src={effect1Img} alt="Effect 1" />
      </Effect1>

      <Effect2>
        <img src={effect2Img} alt="Effect 2" />
      </Effect2>

      <Home />

      <Popular />

      <About />

      <Vision />

      <Roadmap />

      <FAQ />

    </>
  )
}
