import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Text } from 'rebass'

import visionBg from '../../assets/images/vision-bg.png'
import visionIcon1 from '../../assets/images/vision-icon-1.png'
import visionIcon2 from '../../assets/images/vision-icon-2.png'
import visionIcon3 from '../../assets/images/vision-icon-3.png'
import visionNumber1 from '../../assets/images/vision-number-1.png'
import visionNumber2 from '../../assets/images/vision-number-2.png'
import visionNumber3 from '../../assets/images/vision-number-3.png'

const Wrapper = styled.div`
    margin: 42px 0px;
    width: 100%;
    max-width: 1400px;
    background-image: ${({ theme }) => `url(${visionBg})`};
    background-repeat: no-repeat;
    background-size: contain;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin: 12px 0px;
        background: none;
    `};
`

const TextLine = styled.div`
    text-align: center;
`

const Divider = styled.div`
    height: 1px;
    width: 80px;
    background: #05A8EC;
    margin: 24px auto;
`

const FeauresPanel = styled.div`
    display: flex;
    padding: 0px 120px;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 0px 32px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        display: block;
        padding: 0px 32px;
    `};

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0px 12px;
    `};
`

const FeatureItem = styled.div`
    flex: 1;
    position: relative;

    &:nth-child(1) {
        margin-top: 260px;
    }
    &:nth-child(2) {
        margin-top: 160px;
    }
    &:nth-child(3) {
        margin-top: 60px;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
        &:nth-child(1) {
            margin-top: 0px;
        }
        &:nth-child(2) {
            margin-top: 20px;
        }
        &:nth-child(3) {
            margin-top: 20px;
        }
    `};
`

const FeatureIcon = styled.img`
    height: 100px;
    margin-bottom: 60px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-bottom: 24px;
    `};
`

const FeatureNumber = styled.img`
    height: 120px;
    position: absolute;
    top: -30px;
    right: 40px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        top: 40px;
        right: 0px;
    `};
`

const FeatureText = styled(Text)`
    max-width: 300px;
    color: ${({ theme }) => theme.text2};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        max-width: inherit;
    `};
`

const Vision = () => {

    const theme = useContext(ThemeContext);

    return <Wrapper id='vision'>

        <TextLine>

            <Text style={{ fontSize: '32px', fontWeight: 'bold' }}>
                <span style={{ color: theme.primaryText1, marginRight: '8px' }}>Special</span>
                <span style={{ color: theme.primary2 }}>Features</span>
            </Text>

            <Divider />

        </TextLine>

        <FeauresPanel>

            <FeatureItem>
                <FeatureIcon src={visionIcon1} />
                <FeatureNumber src={visionNumber1} />
                <FeatureText>
                    HOKKFi NFTs are a collection of 4444 unique Inus that each come with special attributes. The rarer the attributes, the rarer the NFTs, increasing the potential for that NFT to multiply in value.
                </FeatureText>
            </FeatureItem>
            <FeatureItem>
                <FeatureIcon src={visionIcon2} />
                <FeatureNumber src={visionNumber2} />
                <FeatureText>
                    Once minted, each NFT can be used on any Ethereum based NFT trading platform, with support for other chains in the near future. NFT owners will be able to switch to their blockchain or trading platform of choice.
                </FeatureText>
            </FeatureItem>
            <FeatureItem>
                <FeatureIcon src={visionIcon3} />
                <FeatureNumber src={visionNumber3} />
                <FeatureText>
                    Each NFT is linked to HOKKFi, our portal to your financial ecosystem containing incredible financial products and services we’re building on the blockchain, including a wallet, a DeFi aggregator for your ETH, and so much more. Through HOKKFi, NFT owners can access future key contracts and ecosystem rewards, as well as enjoy the benefits of new exciting features that will be added to the NFTs as the contracts are modular.
                </FeatureText>
            </FeatureItem>

        </FeauresPanel>

    </Wrapper>;
}

export default Vision;