import React, { useState } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'

import { faqs } from 'constants/index'
import { ChevronDown, ChevronRight } from 'react-feather'
import { ButtonEmpty } from 'components/Button'

import faqBg from '../../assets/images/faq-bg.png'


const Wrapper = styled.div`
    width: 100%;
    background-image: ${() => `url(${faqBg})`};
    background-repeat: no-repeat;
    background-size: cover;
    padding: 16px;
`

const Content = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: 24px auto;
`

const TextLine = styled.div`
    width: 100%;
    max-width: 1400px;
    text-align: center;
    margin: 0 auto;
`

const BigText = styled(Text)`
    font-size: 100px;
    color: #6968EA;
    opacity: 0.05;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        display: none;
    `};
`

const MediumText = styled(Text)`
    font-size: 24px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 18px;
    `};
`

const SmallText = styled.div`
    font-size: 32px;
    margin-top: -40px;
`

const FaqPanel = styled.div`
`

const FaqItem = styled.div`
    margin: 24px 0px;
    background: #191A2670;
    border-radius: 24px;
`

const FaqTitle = styled.div`
    display: flex;
    border-radius: 24px;
    background: rgba(13, 14, 26, 0.6);
    padding: 32px 64px;
    align-items: center;
    user-selection: none;
    cursor: pointer;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 24px 42px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 12px 24px;
    `};
`

const FaqDesc = styled.div`
    padding: 32px 64px;
    font-size: 20px;
    line-height: 32px;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 24px 42px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 16px;
        padding: 12px 24px;
    `};
`

const ButtonExpand = styled(ButtonEmpty)`
    width: 80px;
    padding: 0px;
`

const Footer = styled.div`
    display: flex;
    align-items: center;
    margin: 42px 0px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-bottom: 64px;
    `};
`

const Copyright = styled(Text)`
    flex: 1;
    text-align: center;
`

const FAQ = () => {

    const [selectedIdx, setSelectedIdx] = useState<number>(-1);

    const handleExpand = (idx: number) => {
        if (idx === selectedIdx) {
            setSelectedIdx(-1);
        }
        else {
            setSelectedIdx(idx);
        }
    }

    return <Wrapper id='faq'>

        <Content>

            <TextLine>

                <BigText>FAQ</BigText>

                <SmallText>
                    FAQ
                </SmallText>

            </TextLine>

            <FaqPanel>

                {
                    faqs.map((faq, idx) => <FaqItem key={idx}>

                        <FaqTitle onClick={() => handleExpand(idx)}>
                            <MediumText style={{ flex: 1 }}>
                                {faq.name}
                            </MediumText>
                            <ButtonExpand>

                                {
                                    selectedIdx === idx ?
                                        <ChevronDown width={42} height={42} color='#8226E3' /> :
                                        <ChevronRight width={42} height={42} color='#8226E3' />
                                }

                            </ButtonExpand>
                        </FaqTitle>

                        {
                            selectedIdx === idx ? <FaqDesc>
                                <div dangerouslySetInnerHTML={{ __html: faq.description }}></div>
                            </FaqDesc> : null
                        }

                    </FaqItem>)
                }

            </FaqPanel>

            <Footer>
                <Copyright>
                    @ Copyright 2021
                </Copyright>
            </Footer>

        </Content>

    </Wrapper >;
}

export default FAQ;