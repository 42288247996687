import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'

import roadmapBg from '../../assets/images/roadmap-bg.png'
import roadmapSmBg from '../../assets/images/roadmap-line-sm.png'
import roadmapImg from '../../assets/images/roadmap-line.png'


const Wrapper = styled.div`
    width: 100%;
    max-width: 1400px;
    background-image: ${() => `url(${roadmapBg})`};
    background-repeat: no-repeat;
    background-size: cover;
    padding: 42px 0px;
    margin: 24px 0px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin: 12px 0px;
    `};
`

const TextLine = styled.div`
    text-align: center;
`

const Divider = styled.div`
    height: 1px;
    width: 80px;
    background: #F57C36;
    margin: 24px auto;
`

const RoadmapPanel = styled.div`
    display: grid;
    grid-template-columns: 1fr 400px 1fr;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        display: none;
    `};
`

const RoadmapImg = styled.img`
    width: 100%;
`

const LeftPanel = styled.div`
    position: relative;

    .roadmap {
        right: 0px;
    }

    & .roadmap:nth-child(1) {
        margin-top: 200px;
    }
    & .roadmap:nth-child(2) {
        margin-top: 500px;
    }
`

const RightPanel = styled.div`
    position: relative;

    & .roadmap:nth-child(1) {
        margin-top: 80px;
    }
    & .roadmap:nth-child(2) {
        margin-top: 360px;
    }
    & .roadmap:nth-child(3) {
        margin-top: 600px;
    }
`

const RoadmapSmPanel = styled.div`
    display: none;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        display: flex;
        justify-content: center;
    `};
`

const RoadmapSmBg = styled.img`
    object-fit: cover;
`

const RoadmapItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 54px;
    margin-left: 42px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-left: 24px;
    `};

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        margin-left: 12px;
    `};
`

const RoadmapItem = styled.div`
    color: #C9B7DF;
    border-radius: 24px;
    border: 1px solid #8B53FF80;
    padding: 24px;
    width: 300px;
    position: absolute;
    
    ${({ theme }) => theme.mediaWidth.upToMedium`
        position: inherit;
    `};

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        width: 100%;
    `};
`

const Roadmap = () => {

    return <Wrapper id='roadmap'>

        <TextLine>
            <Text fontSize={'32px'} fontWeight={'bold'}>
                ROADMAP
            </Text>
            <Divider />
        </TextLine>

        <RoadmapPanel>

            <LeftPanel>
                <RoadmapItem className='roadmap'>
                    5 NFT owners will be randomly selected and gifted 1,000 USDC + 1 NFT. <br />
                    The Utility integration begins with multiple Dapps being developed around the NFT's.
                </RoadmapItem>
                <RoadmapItem className='roadmap'>
                    5 NFT owners will be randomly selected and gifted 2,000 USDC + 3 NFT
                </RoadmapItem>
            </LeftPanel>

            <RoadmapImg src={roadmapImg} />

            <RightPanel>
                <RoadmapItem className='roadmap'>
                    10 NFT Owners will be randomly selected and gifted 500 USDC each.
                </RoadmapItem>
                <RoadmapItem className='roadmap'>
                    1 NFT owner will be randomly selected and gifted 5,000 USDC + 5 NFT (Halfway Mark!)
                </RoadmapItem>
                <RoadmapItem className='roadmap'>
                    1 NFT owner will be randomly selected and gifted 10,000 USDC + 10 NFT
                </RoadmapItem>
            </RightPanel>

        </RoadmapPanel>

        <RoadmapSmPanel>

            <RoadmapSmBg src={roadmapSmBg} />

            <RoadmapItems>

                <RoadmapItem className='roadmap'>
                    10 NFT Owners will be randomly selected and gifted 500 USDC each.
                </RoadmapItem>
                <RoadmapItem className='roadmap'>
                    5 NFT owners will be randomly selected and gifted 1,000 USDC + 1 NFT. <br />
                    The Utility integration begins with multiple Dapps being developed around the NFT's.
                </RoadmapItem>
                <RoadmapItem className='roadmap'>
                    1 NFT owner will be randomly selected and gifted 5,000 USDC + 5 NFT (Halfway Mark!)
                </RoadmapItem>
                <RoadmapItem className='roadmap'>
                    5 NFT owners will be randomly selected and gifted 2,000 USDC + 3 NFT
                </RoadmapItem>
                <RoadmapItem className='roadmap'>
                    1 NFT owner will be randomly selected and gifted 10,000 USDC + 10 NFT
                </RoadmapItem>

            </RoadmapItems>

        </RoadmapSmPanel>

    </Wrapper>;
}

export default Roadmap;