import { Contract } from '@ethersproject/contracts'
import { useMemo } from 'react'
import { ChainId } from '@uniswap/sdk'
import ERC20_ABI from '../constants/abis/erc20.json'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract } from '../utils'
import { useActiveWeb3React } from './index'
import { useSingleCallResult } from 'state/multicall/hooks'
import { BigNumber } from 'ethers'
import { HOKKNFT_ABI, HOKKNFT_ADDRESS } from 'constants/abis/hokknft'

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.GÖRLI:
      case ChainId.ROPSTEN:
      case ChainId.RINKEBY:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useNftContract(): Contract | null {
  return useContract(HOKKNFT_ADDRESS, HOKKNFT_ABI, false)
}

export function useMintPrice(): BigNumber {
  const contract = useNftContract()
  const amountResult = useSingleCallResult(contract, 'mintCost')
  return amountResult.result?.[0];
}

export function useTotalSupply(): BigNumber {
  const contract = useNftContract()
  const amountResult = useSingleCallResult(contract, 'totalSupply')

  if (amountResult.result) {
    return amountResult.result?.[0];
  }
  else {
    return BigNumber.from(0);
  }
}

export function useMintLimit(): BigNumber {
  const contract = useNftContract()
  const amountResult = useSingleCallResult(contract, 'mintLimit')

  if (amountResult.result) {
    return amountResult.result?.[0];
  }
  else {
    return BigNumber.from(4444);
  }
}

