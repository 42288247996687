import React from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'

import styled from 'styled-components'

import { useActiveWeb3React } from '../../hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'

import logoImg from '../../assets/images/logomain.png'
import discordIcon from '../../assets/images/icon-discord.png'
import telegramIcon from '../../assets/images/icon-telegram.png'
import twitterIcon from '../../assets/images/icon-twitter.png'
import { ExternalLink } from 'theme'


const HeaderFrame = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 24px 0px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 2;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0px;
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: center;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: #151515;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
  
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  gap: 24px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 1rem 1rem;
    justify-content: flex-end;
    gap: 12px;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  padding-left: 8px;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

/*
const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`
*/

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`


const LogoIcon = styled.div`
  img {
    width: 100px;
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 4px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const SocialLink = styled(ExternalLink)`

  margin: 0px 6px;

  & img {
    width: 40px;
  }
`

/*
const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan'
}
*/

export default function Header() {
  // const { account, chainId } = useActiveWeb3React()
  const { account } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  return (
    <HeaderFrame>
      <LogoIcon>
        <ExternalLink href="https://hokkfi.com/" target="_blank">
          <img src={logoImg} alt="HOKKFi" />
        </ExternalLink>
      </LogoIcon>
      <HeaderRow>
        <HeaderLinks>
          <ExternalLink href="https://hokk.finance/" target="_blank">
            Home
          </ExternalLink>
          <StyledNavLink id={`about-link`} to={'about'}>
            About
          </StyledNavLink>
          <StyledNavLink id={`vision-link`} to={'vision'}>
            Vision
          </StyledNavLink>
          <StyledNavLink id={`roadmap-link`} to={'roadmap'}>
            Roadmap
          </StyledNavLink>
          <StyledNavLink id={`faq-link`} to={'faq'}>
            FAQ
          </StyledNavLink>
        </HeaderLinks>
      </HeaderRow>
      <HeaderControls>
        <HeaderElementWrap>
          <SocialLink href="https://twitter.com/HOKKFinance" target="_blank">
            <img src={twitterIcon} alt="Twitter" />
          </SocialLink>
          <SocialLink href="https://t.me/HokkFinance" target="_blank">
            <img src={telegramIcon} alt="Telegram" />
          </SocialLink>
          <SocialLink href="https://discord.gg/xgQkPa3P42" target="_blank">
            <img src={discordIcon} alt="Discord" />
          </SocialLink>
        </HeaderElementWrap>
        <HeaderElement>
          {
            /*
            <HideSmall>
              {chainId && NETWORK_LABELS[chainId] && (
                <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
              )}
            </HideSmall>
            */
          }
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} ETH
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}
