import React, { useContext } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { nfts } from 'constants/index';

const Wrapper = styled.div`
    margin-bottom: 24px;
    display: contents;
`

const PopularText = styled.div`
    font-size: 32px;
    margin-bottom: 42px;
`

const PopularItem = styled.div`
    position: relative;
    user-select: none;
    width: 320px;
`

const NftDrop = styled.div`
    width: 300px;
    height: 300px;
    background: linear-gradient(180deg, rgba(144, 83, 189, 0) 0%, #9053BD 100%);
    border-radius: 16px;
    backdrop-filter: blur(40px);
    opacity: 0.2;
    padding: 24px;
    margin-bottom: 24px;
`

const NftImg = styled.div<{ src: string }>`
    position: absolute;
    top: 25px;
    left: 25px;
    width: 250px;
    height: 250px;
    background-image: ${(item) => `url(${item.src})`};
    background-size: contain;
`

const NftText = styled(Text)`
    text-align: center;
`

const PopularItems = styled.div`
    width: 100%;
    display: block;
    user-select: none;

    .alice-carousel__prev-btn-item,
    .alice-carousel__next-btn-item {
        font-size: 42px;
        color: #FFFFFF;
    }

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        padding: 10px 32px;
    }
`

const Popular = () => {

    const theme = useContext(ThemeContext);

    return <Wrapper>

        <PopularText>
            <span style={{ color: theme.primaryText1, marginRight: '4px' }}>Most</span>
            <span style={{ color: theme.primary2 }}>Popular</span>
        </PopularText>

        <PopularItems>

            <AliceCarousel
                mouseTracking
                disableDotsControls={true}
                autoWidth={true}
                infinite={true}
            >

                {
                    nfts.map((nft, idx) => <PopularItem key={idx}>

                        <NftDrop />
                        <NftImg src={nft.img} />
                        <NftText>{nft.name}</NftText>

                    </PopularItem>)
                }

            </AliceCarousel>

        </PopularItems>


    </Wrapper >;
}

export default Popular;