import { JSBI, Percent } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 400
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')


export interface NftItem {
  name: string
  img: string
}

export const nfts: Array<NftItem> = [
  {
    name: "Spacesuit Gold",
    img: 'images/nfts/1.jpg'
  },
  {
    name: "King",
    img: 'images/nfts/2.jpg'
  },
  {
    name: "Clown",
    img: 'images/nfts/3.jpg'
  },
  {
    name: "Toga",
    img: 'images/nfts/4.jpg'
  },
  {
    name: "Butterfly",
    img: 'images/nfts/5.jpg'
  },
  {
    name: "Bee",
    img: 'images/nfts/6.jpg'
  },
  {
    name: "Samurai",
    img: 'images/nfts/7.jpg'
  },
  {
    name: "Toga Devil",
    img: 'images/nfts/8.jpg'
  },
  {
    name: "Admiral",
    img: 'images/nfts/9.jpg'
  }
];


export interface FaqType {
  name: string
  description: string
}

export const faqs: Array<FaqType> = [
  {
    name: "What is a NFT?",
    description: "NFTs, or non-fungible tokens, are unique digital assets that are stored on a blockchain. NFTs are being used to purchase digital representations of traditional artworks, videos and photographs, and even GIFs, tweets and virtual real estate. <br/><br/>    Unlike cryptocurrencies, NFTs are one of a kind (and not fungible) – meaning that they cannot be traded or interchanged. Think of them as a kind of digital passport – each has a singular identity that enables them to be distinguished from others. Artists can also sign their digital artwork with their own signature. NFTs provide proof of ownership with a private key and a certificate of authenticity, making the artwork truly yours. <br/><br/>    NFTs offer a new lease of life in the space of digital finance. Financial organizations use trading and lending systems for a variety of asset categories including real estate, lending contracts, and artworks. NFTs transform this infrastructure by adding to the mix digital representations of physical assets."
  },
  {
    name: "What’s the Best Way for Me To Sell My NFTs?",
    description: "<a href='https://opensea.io/' target='_blank'>OpenSea</a> was one of the first marketplaces established for buying and selling NFTs. This platform, in addition to other notable platforms including Rarible, SuperRare, and Mintable, has benefitted the art industry enormously, allowing artists to make a good living from their work."
  },
  {
    name: "What are the most valuable NFTs?",
    description: "In March 2021, <a href='https://news.artnet.com/market/updated-most-expensive-nfts-1980942' target='_blank'>Artnet News</a> produced a list of the most expensive NFT artworks ever to be sold, with Beeple's Everydays – The First 5000 Days topping the list by selling for a whopping $69 million. This sale took place on March 11, propelling the artist, Mike Winkelmann, to third place on the list of the most valuable living artists in terms of auction prices. <br/><br/>    CryptoPunks and <a href='https://boredapeyachtclub.com/#/' target='_blank'>Bored Ape</a> Yacht Club are currently the most costly NFT collections. Created by Larva Labs, a New-York based software company run by Matt Hall and John Watkinson, <a href='https://www.larvalabs.com/cryptopunks' target='_blank'>CryptoPunks</a> are uniquely produced pixel art figures on the Ethereum blockchain. In June 2017, a total of 10,000 CryptoPunks were generated using code. Initially, anyone with an ETH wallet could get them for free. They have, however, become part of a booming resale market, commanding a broad variety of prices. Out of the 10,000 figures, only 9 alien figures were created. If you’re looking to get your (digital) hands on one of these aliens, this will set you back a cool $5.59 million.<br/><br/>    The Bored Ape Yacht Club is a collection of 10,000 unique bored ape NFTs, each with its own personality and aesthetic. The Bored Ape Yacht Club is a hugely popular collection, with star-studded owners such as Jimmy Fallon, Steph Curry, and Post Malone. The current entrance price – that is, the lowest price for a <a href='https://nerdshala.com/how-a-300k-bored-ape-yacht-club-nft-accidentally-sold-for-3k/' target='_blank'>Bored Ape Yacht Club NFT – is 52 ether, or $210,000, which incidentally was sold by accident for $3,000.</a>"
  },
  {
    name: "What is the next era for NFTs?",
    description: "The hype around NFTs isn't going away anytime soon. Celebrities such as Paris Hilton and Simone Biles have jumped on the bandwagon, creating their own NFTs, and big corporations like Charmin and Taco Bell have joined in too. <br/><br/>    There’s no way around it: NFTs are a game-changer. They allow for more members of the public to own art, a luxury that has typically been the preserve of the rich. Even art appraisal, which has traditionally been the domain of a limited group of aficionados, has evolved into a more democratic process. <br/><br/>    NFTs are almost limitless in their use. Some predict that in 10 years all purchases will involve NFTs, and that smart contracts will eventually supplant legal paperwork. While various individuals believe NFTs to be a fad, or too esoteric to be adopted by many, major companies such as Meta (previously Facebook), Twitter, Reddit, and Visa are paying attention to this trend, and are working to ensure that they don't lose out on the NFT boom."
  },
  {
    name: "Who is eligible for the giveaway mentioned in the road map?",
    description: "Only NFT holders at the time of reaching the milestone will be eligible"
  }
];