import styled, { ThemeContext } from 'styled-components'
import React, { useContext, useState } from 'react'

import mintBg from '../../assets/images/mint-bg.png'
import { ButtonLight } from 'components/Button'
import { ExternalLink, TYPE } from 'theme'
import { Input } from '@mui/material'
import { useWalletModalToggle } from 'state/application/hooks'
import { useActiveWeb3React } from 'hooks'
import { useMintLimit, useMintPrice, useTotalSupply } from 'hooks/useContract'
import { calculateGasMargin, getNftContract, tryParseAmount } from 'utils'
import { BigNumber } from 'ethers'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks'
import { Text } from 'rebass'
import { OPENSEA_URL } from 'connectors/index'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 54px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
  `};
`

const HomeWrapper = styled.div`
  flex: 1;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;    
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    text-align: center;
  `};
`

const MintWrapper = styled.div`
  flex: 1;
`

const HomeContainer = styled.div`
  margin: 40px auto;
  padding: 0px 42px;
  width: 500px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `};
`

const MintContainer = styled.div`
  width: 700px;
  text-align: center;
  margin: 0px auto;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 500px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 500px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 400px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `};
`

const MintBg = styled.img`
  width: 100%;
  margin-top: -40px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 0px;
  `};
`

const MintPanel = styled.div`
  width: 100%;
  background: rgba(10, 9, 16, 0.38);
  border-radius: 33px;
  border: 1px solid #4E44CE;
  padding: 0px 24px 42px 24px;
  backdrop-filter: blur(40px);
  margin-top: -300px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-top: -220px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: -210px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: -180px;
    padding: 0px 12px 24px 12px;
  `};
`

const ButtonExplore = styled(ButtonLight)`
  margin-top: 42px;
  width: 240px;
  box-shadow: 10px 20px 20px 0px rgba(237, 65, 103, 0.4);

  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
    box-shadow: 10px 20px 20px 0px rgba(237, 65, 103, 0.4);
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 auto;
  `};
`

const ButtonMint = styled(ButtonLight)`
  width: 120px;
  padding: 12px;
`

const MintLine = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 12px;
    flex-direction: column;
  `};
`

const MintAmountInput = styled(Input)`
  background: #FFFFFF;
  border-radius: 8px;
  width: 120px;
  height: 44px;
  font-size: 24px; 

  & input {
    text-align: center;
  }
`

const MediumText = styled(Text)`
    font-size: 16px;
    line-height: 28px;
    padding: 24px 0px;
`


const Home = () => {

  const theme = useContext(ThemeContext);
  const { account, chainId, library } = useActiveWeb3React();
  const toggleWalletModal = useWalletModalToggle();
  const price = useMintPrice();
  const addTransaction = useTransactionAdder();

  const mintedAmount = useTotalSupply();
  const totalAmount = useMintLimit();
  const [mintAmount, setMintAmount] = useState<number>(1);
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const isPending = useIsTransactionPending(txHash);

  const handleFocus = (e: any) => {
    e.target.select();
  }

  const handleChangeAmount = (e: any) => {
    const { value } = e.target;

    if (value > 0
      && value <= 10) {
      setMintAmount(value);
    }
  }

  /*
  useEffect(() => {

    const changeNetwork = async () => {

      if (account && chainId !== NETWORK_CHAIN_ID) {

        const ethereum = window.ethereum;

        try {
          await ethereum?.request({
            method: 'wallet_switchEthereumChain',
            params: [{
              chainId: NETWORK_CHAIN_ID
            }],
          });
        }
        catch (switchError) {
          if (switchError.code === 4902) {
            try {
              await ethereum?.request({
                method: 'wallet_addEthereumChain',
                params: [NETWORK_CHAIN_ID],
              });
            } catch (addError) {
              setErrorMessage('Get error while add chain.');
            }
          }
          else {
            setErrorMessage('Get error while switch chain.');
          }
        }
      }
    }

    changeNetwork();

  }, [
    account,
    chainId
  ])
  */

  const connectWallet = async () => {

    if (!account) {
      toggleWalletModal();
    }

  }

  const handleMint = () => {

    if (!account) {
      toggleWalletModal();
    }

    mint(mintAmount);
  }

  async function mint(amount: number) {
    if (!chainId || !library || !account) return
    const contract = getNftContract(chainId, library, account)

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null;

    estimate = contract.estimateGas.createNFT
    method = contract.createNFT
    args = [
      amount, // amount 
      account, // claimer 
      0 // ownerMint 
    ]
    value = BigNumber.from(amount).mul(price);

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary:
              'Mint Successed.'
          })
          setTxHash(response.hash)
          setErrorMessage('');
        })
      )
      .catch(error => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          if (error.reason) {
            setErrorMessage(error.reason);
          }
          else {
            setErrorMessage('Get error while mint NFT.');
          }
        }
        else {
          setErrorMessage('User rejected transaction.');
        }
      })
  }


  return <Wrapper>

    <HomeWrapper>

      <HomeContainer>
        <h1>THE NEXT GENERATION OF NFTs STARTS HERE</h1>
        <MediumText>Introducing 4444 one-of-a-kind NFTs bringing you closer to decentralized finance and the future of Meme-UtilityⓇ</MediumText>
        <ButtonExplore as={ExternalLink} href={OPENSEA_URL} target='_blank'>Explore</ButtonExplore>
      </HomeContainer>

    </HomeWrapper>

    <MintWrapper>

      <MintContainer>
        <MintBg src={mintBg} />
        <MintPanel>
          <h1>Mint</h1>
          <h4>Enter the amount of NFTs you would like to buy ( 10 max ):</h4>

          <MintLine>
            <MintAmountInput
              value={mintAmount}
              onChange={handleChangeAmount}
              onFocus={handleFocus}
            />

            {
              account
                ? <ButtonMint onClick={handleMint} disabled={isPending || attemptingTxn}>MINT</ButtonMint>
                : <ButtonMint onClick={connectWallet}>CONNECT</ButtonMint>
            }

            <TYPE.body color={theme.red2}>Total: {price ? tryParseAmount(price.mul(mintAmount))?.toExact() : '0'} ETH</TYPE.body>

          </MintLine>

          <TYPE.error error={true} marginTop={12}>{errorMessage}</TYPE.error>

          <TYPE.mediumHeader marginTop={24}>Mint Process: {mintedAmount.toString()} / {totalAmount.toString()}</TYPE.mediumHeader>

        </MintPanel>
      </MintContainer>

    </MintWrapper>

  </Wrapper>;
}

export default Home;