import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Text } from 'rebass'
import aboutBg from '../../assets/images/about-bg.png'
import aboutImg from '../../assets/images/about-img.png'

const Wrapper = styled.div`
    margin: 42px 0px;
    display: block;
`

const TextLine = styled.div`
    width: 100%;
    max-width: 1400px;
    text-align: center;
    margin: 0px auto;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 0px 42px;
    `};
`


const BigText = styled(Text)`
    font-size: 100px;
    color: #6968EA;
    opacity: 0.05;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        font-size: 64px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        display: none;
    `};
`

const MediumText = styled(Text)`
    font-size: 16px;
    padding-top: 42px;
    line-height: 28px;
`

const SmallText = styled.div`
    font-size: 32px;
    margin-top: -40px;
`

const ImageLine = styled.div`
    position: relative;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        height: 320px;
    `};
`

const AboutBg = styled.img`
    width: 100vw;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        display: none;
    `};
`

const AboutImg = styled.img`
    width: 250px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);

    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: 200px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        top: 160px;
        width: 300px;
    `};
`

const About = () => {

    const theme = useContext(ThemeContext);

    return <Wrapper id='about'>

        <TextLine>

            <BigText>HOKKFI NFTs NFTs</BigText>

            <SmallText>
                <span style={{ color: theme.primaryText1, marginRight: '8px' }}>HOKKFi</span>
                <span style={{ color: theme.primary2 }}>NFTs</span>
            </SmallText>

            <MediumText >
                A collection of 4444 unique HOKK Finance NFTs are available for minting. These NFTs will solidify HOKK Finance’s status as a meme token that embraces industry utility. They can then be marketed as utility NFTs, taking advantage of the true power of DeFi protocols to grant them perpetual value. Each NFT will come with its own set of unique stats and images. Due to the extensive NFT trading volume on OpenSea, the minting will take place on the Ethereum blockchain. Collectors will be able to visit a HOKK controlled domain and mint NFTs freely.
            </MediumText>

        </TextLine>

        <ImageLine>

            <AboutBg src={aboutBg} />
            <AboutImg src={aboutImg} />

        </ImageLine>

    </Wrapper>;
}

export default About;